import { z } from 'zod';
import { CLIENT_TYPE } from '../../../utils/consts/clientType';

const commonFields = {
  street: z.string().min(2, 'invalidStreet').optional(),
  city: z.string().min(1),
  zipCode: z
    .string()
    .length(5, 'invalidZipCode')
    .regex(/^[0-9]+$/, 'invalidZipCode'),
  country: z.string().min(1, 'invalidCountry'),
  area: z.string().min(2, 'invalidArea'),
  sameBillingShippingAddress: z.boolean(),
};
const companySchema = z.object({
  clientType: z.literal(CLIENT_TYPE.COMPANY),
  company: z.string().min(2, 'invalidCompanyName'),
  vatCode: z
    .string()
    .trim()
    .min(1, 'vatCodeTooShort')
    .regex(/^(IT|it)?\d{11}$/, 'invalidVatCode'),
  fiscalCode: z.string().max(16, 'fiscalCodeTooLong').min(11, 'fiscalCodeTooShort'),
  sdiCode: z.string().optional(),
  sdiCodeCheck: z.boolean().default(false),
  pec: z.string().optional(),
  ...commonFields,
});
const physicalPersonSchema = z.object({
  clientType: z.literal(CLIENT_TYPE.PHYSICAL_PERSON),
  name: z.string().min(2, 'invalidName'),
  surname: z.string().min(2, 'invalidSurname'),
  fiscalCode: z.string().max(16, 'fiscalCodeTooLong').min(11, 'fiscalCodeTooShort'),
  ...commonFields,
});

export const formSchema = z
  .discriminatedUnion('clientType', [companySchema, physicalPersonSchema])
  .superRefine((data, ctx) => {
    if (data.clientType === CLIENT_TYPE.COMPANY) {
      if (!data.sdiCodeCheck) {
        // Validate `sdiCode` when `sdiCodeCheck` is false
        if (!data.sdiCode || data.sdiCode.trim().length < 7) {
          ctx.addIssue({
            path: ['sdiCode'],
            message: 'invalidSdiCode',
            code: z.ZodIssueCode.too_small,
            minimum: 7,
            type: 'string',
            inclusive: true,
          });
        }
      } else {
        // Validate `pec` when `sdiCodeCheck` is true
        if (!data.pec || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.pec)) {
          ctx.addIssue({
            path: ['pec'],
            message: 'invalidPec',
            code: z.ZodIssueCode.invalid_string,
            validation: 'email',
          });
        }
      }
    }
  });
